@import 'bootstrap/scss/bootstrap';

$bd-violet: #7f00ffe0;

:root {
  --bd-violet: #7f00ffe0;
}

.btnPrimary {
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet);
  --bs-btn-border-color: var(--bd-violet);
  // --bs-btn-border-radius: 0.5rem;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #{shade-color($bd-violet, 10%)};
  --bs-btn-hover-border-color: #{shade-color($bd-violet, 10%)};
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #{shade-color($bd-violet, 20%)};
  --bs-btn-active-border-color: #{shade-color($bd-violet, 20%)};
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #9d3dfd;
  --bs-btn-disabled-border-color: #9d3dfd;
}

.btnPrimaryUserNo {
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bd-violet);
  --bs-btn-bg: 'transparent';
  --bs-btn-border-color: #bb91e6e0;
  --bs-btn-border-radius: 18px;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #{shade-color(#e71870, 10%)};
  --bs-btn-hover-border-color: #{shade-color(#e71870, 10%)};
  --bs-btn-focus-shadow-rgb: #e71870;
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #e71870;
  --bs-btn-active-border-color: #e71870;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #{shade-color(#e71870, 20%)};
  --bs-btn-disabled-border-color: #{shade-color(#e71870, 20%)};
}

.btnPrimaryUserNo.active:hover {
  background-color: #d3005a;
  border-color: #d3005a;
}

.btnPrimaryUser {
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bd-violet);
  --bs-btn-bg: 'transparent';
  --bs-btn-border-color: #bb91e6e0;
  --bs-btn-border-radius: 18px;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #{shade-color(#3bbe3b, 10%)};
  --bs-btn-hover-border-color: #{shade-color(#3bbe3b, 10%)};
  --bs-btn-focus-shadow-rgb: #3bbe3b;
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #3bbe3b;
  --bs-btn-active-border-color: #3bbe3b;
}

.btnPrimaryHelp {
  --bs-btn-font-weight: 600;
  --bs-btn-color: #fff;
  --bs-btn-bg: #0000003d;
  --bs-btn-border-color: none;
  --bs-btn-border-radius: 18px;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #{shade-color(#261c5d, 10%)};
  --bs-btn-hover-border-color: #{shade-color(#261c5d, 10%)};
  --bs-btn-focus-shadow-rgb: #261c5d;
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #261c5d;
  --bs-btn-active-border-color: #261c5d;
}

.btnPrimaryChipFooter {
  --bs-btn-font-weight: 600;
  --bs-btn-color: #0a070d96;
  --bs-btn-bg: #efecff;
  --bs-btn-border-color: #e3deff;
  --bs-btn-border-radius: 18px;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #{shade-color(#6554c0, 10%)};
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-shadow-rgb: #0000008c;
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #0000008c;
  --bs-btn-active-border-color: #0000008c;
}

.btnPrimaryChipFooter:disabled,
.btnPrimaryChipFooter.disabled,
fieldset:disabled .btnPrimaryChipFooter {
  color: #cbcbcb !important;
  pointer-events: none;
  background-color: transparent;
  border-color: #cbcbcb !important;
  /* opacity: var(--bs-btn-disabled-opacity); */
}

.btnPrimaryChip {
  --bs-btn-font-weight: 600;
  --bs-btn-color: #fff;
  --bs-btn-bg: #0000003d;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 18px;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #{shade-color(#0000008c, 10%)};
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-shadow-rgb: #0000008c;
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #0000008c;
  --bs-btn-active-border-color: #0000008c;
}

.btnPrimaryChip:disabled,
.btnPrimaryChip.disabled,
fieldset:disabled .btnPrimaryChip {
  color: #fff !important;
  pointer-events: none;
  background-color: #0000003d;
  border-color: transparent !important;
  opacity: 1;
}
.btnPrimaryComments {
  font-size: 12px;
  --bs-btn-font-weight: 400;
  --bs-btn-color: #9384e5;
  --bs-btn-bg: 'transparent';
  --bs-btn-border-color: #dedaf4;
  --bs-btn-border-radius: 18px;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #a163e1;
  --bs-btn-hover-border-color: #a163e1;
  --bs-btn-focus-shadow-rgb: #a163e1;
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #a163e1;
  --bs-btn-active-border-color: #a163e1;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  color: white;
  opacity: 1;
  background-color: #b56aff !important;
  border: 2px solid #b56aff !important;
}

.rdp-head_cell {
  color: #a193fa;
}

.tabview-demo .tabview-header-icon i,
.tabview-demo .tabview-header-icon span {
  vertical-align: middle;
}

.tabview-demo .tabview-header-icon span {
  margin: 0 0.5rem;
}

.tabview-demo .p-tabview p {
  line-height: 1.5;
  margin: 0;
}

.hideMenu {
  display: none !important;
}

.p-dropdown-panel {
  border: 1px solid #ced4da;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 4px 8px;
  font-size: 10px;
  text-transform: uppercase;
}

.p-error {
  color: #e24c4c;
  font-size: 12px;
  /* line-height: 0px; */
  /* width: 100%; */
  display: block;
}

.tooltipCustom {
  z-index: 1101;
}

.customPicker table td {
  padding: 1px;
  font-size: 12px;
}
.customPicker table th {
  padding: 1px;
  font-size: 12px;
  text-align: center;
}

.customPicker {
  width: 285px !important;
}

.customPicker table td > span {
  width: 30px;
  height: 30px;
}

.requestLeaveCustomPicker table td {
  padding: 1px;
  font-size: 12px;
}
.requestLeaveCustomPicker table th {
  padding: 1px;
  font-size: 12px;
  text-align: center;
}

.requestLeaveCustomPicker {
  min-width: 285px !important;
}

.requestLeaveCustomPicker table td > span {
  width: 30px;
  height: 30px;
}

.groupBtns {
  line-height: 12px;
  font-size: 12px !important;
}

.customTable .p-datatable-tbody > tr > td {
  padding: 0.5rem 1rem;
  font-size: 0.725rem;
}

.customTable .p-column-title {
  font-size: 12px;
}

.customTable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 0.787rem 1rem !important;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: #343a40;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}

.customTable .p-datatable-header {
  background: #f8f9fa;
  color: #343a40;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 0.787rem 1rem !important;
  font-weight: 700;
}
