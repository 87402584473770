.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.p-dropdown-panel {
	z-index: 9999 !important;
}
.offcanvas-body {
	overflow-y: inherit !important;
}

.p-dropdown-panel {
	border: 1px solid #ced4da;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
	padding: 4px 8px;
	font-size: 10px;
	text-transform: uppercase;
}

.p-error {
	color: #e24c4c;
	font-size: 12px;
	/* line-height: 0px; */
	/* width: 100%; */
	display: block;
}

.tooltipCustom {
	z-index: 1101;
}

.customPicker table td {
	padding: 1px;
	font-size: 12px;
}
.customPicker table th {
	padding: 1px;
	font-size: 12px;
	text-align: center;
}

.customPicker {
	width: 285px !important;
}

.customPicker table td > span {
	width: 30px;
	height: 30px;
}

.requestLeaveCustomPicker table td {
	padding: 1px;
	font-size: 12px;
}
.requestLeaveCustomPicker table th {
	padding: 1px;
	font-size: 12px;
	text-align: center;
}

.requestLeaveCustomPicker {
	min-width: 285px !important;
}

.requestLeaveCustomPicker table td > span {
	width: 30px;
	height: 30px;
}
