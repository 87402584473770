/* .sidebarMenu {
	color: #888;
	cursor: pointer;
	padding-right: 16px;
}

.sidebarMenu:hover {
	color: #7f00ffe0;
	background-color: #f5ebff;
} */

.sidebarMenu.active {
	color: #7f00ffe0;
	background-color: #f5ebff;
	border-right: 3px solid #7f00ffe0;
	padding-right: 13px;
}
